.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(255 238 220);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header > .header {
  width: calc(100vw - 120px);
  background-color: rgb(247 201 126);
  padding: 20px;
  border-radius: 20px;
  margin-top: 40px
}

.App-header > .header > .socials {
  margin: 0 auto;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
}

.App-header > .header > .socials > a {
  display: block;
  height: 40px;
}

.App-header > .header > .socials > a > .social {
  max-height: 40px;
}

.center_content {
  padding-top: 40px;
  margin: 0 auto;
}

.center_content {
  max-width: 400px;
}

.center_content > img.center_content {
  width: 100%;
  max-width: 100%;
}

.token_details {
  width: calc(100vw - 120px);
  background-color: rgb(247 201 126);
  padding: 40px;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 80px;
  box-sizing: border-box;
}

.token_details > .header {
  font-size: 24px;
  font-weight: 600;
  padding-top: 20px;
}

.token_details > .details {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 24px;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.token_details > .details > div {
  padding: 0 20px;
}

.phrase {
  font-size: 48px;
  color: #5B4200;
}

.memes_wrapper {
  padding: 40px
}

.memes_wrapper > .header {
  font-size: 48px;
  padding: 40px 0;
}

.memes_wrapper > .memes {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.memes_wrapper > .memes > .meme {
  width: 400px;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 0;
}

.token_address {
  padding: 40px 0;
  font-size: 16px;
  font-weight: 600;
}
