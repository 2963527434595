@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');

body {
  min-height: 100vh;
  background-color: #FFEEDC;
  margin: 0;
  font-family: "Gochi Hand", cursive;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5b4200;
  font-weight: 400
}
